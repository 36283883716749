import {merge} from 'lodash'
import type {UnwrapNestedRefs} from 'vue'
import {reactive} from 'vue'
import {arrayify, logger, placeholderReplacement, use} from '@/libs/utils'
import {cache, http} from '@/services'
import type {Title, TitleBackStage} from "@/libs/types/title";
import {Endpoints} from "@/libs/EndpointConstant";
import type {Season} from "@/libs/types/season";
import type {Episode} from "@/libs/types/episode";
import type {ApiData} from "@/libs/types/apiData";

export const titleStore = {
    vault: new Map<string, UnwrapNestedRefs<Title>>(),

    state: reactive({
        titles: [] as Title[]
    }),

    byUuid(uuid: string | null) {
        return this.vault.get(uuid as string)
    },

    byUuids(uuIds: string[]) {
        const titles = [] as Title[]
        uuIds.forEach(uuid => use(this.byUuid(uuid), title => titles.push(title!)))
        return titles
    },

    async resolve(uuid: string | null) {
        let title = this.byUuid(uuid)

        if (!title) {
            try {
                title = this.syncWithVault(
                    await cache.remember<Title>(['title', uuid], async () => await http.get<Title>(placeholderReplacement(Endpoints.MOVIE_INFO, {'uuid': uuid})))
                )[0]
                //title = this.syncWithVault(await http.get<Title>(placeholderReplacement(Endpoints.MOVIE_INFO, {'uuid': uuid})))[0]
            } catch (e: any) {
                logger.error(e)
            }
        }

        return title
    },

    /**
     * Increase a play count for a title.
     */
    // registerPlay: async (title: Title) => {
    //     const interaction = await http.post<Interaction>('interaction/play', { title: title.uuid })
    //
    //     // Use the data from the server to make sure we don't miss a play from another device.
    //     title.play_count = interaction.play_count
    // },


    syncWithVault(titles: Title | Title[]) {
        return arrayify(titles).map(title => {
            let local = this.byUuid(title.uuid)

            if (local) {
                merge(local, title)
            } else {
                local = reactive(title)
                //local.playback_state = 'Stopped'
                //this.watchPlayCount(local)
                this.vault.set(local.uuid, local)
            }

            return local
        })
    },

    async cacheable(key: any, fetcher: Promise<Title[]>) {
        return await cache.remember<Title[]>(key, async () => this.syncWithVault(await fetcher))
    },

    async fetchRelated(title: Title | string) {
        const uuid = typeof title === 'string' ? title : title.uuid
        const cacheKey = ['title.related', uuid]
        if (cache.has(cacheKey)) return cache.get<Title[]>(cacheKey)

        const {data} = await http.get<ApiData>(placeholderReplacement(Endpoints.MOVIE_RELATED, {'uuid': uuid}))
        data && cache.set(cacheKey, data)

        return data
    },

    async fetchBackStage(title: Title | string) {
        const uuid = typeof title === 'string' ? title : title.uuid
        const cacheKey = ['title.back-stage', uuid]
        if (cache.has(cacheKey)) return cache.get<TitleBackStage[]>(cacheKey)

        const {data} = await http.get<ApiData>(placeholderReplacement(Endpoints.MOVIE_BACKSTAGE, {'uuid': uuid}))
        data && cache.set(cacheKey, data)

        return data
    },
    async fetchSeasons(title: Title | string) {
        const uuid = typeof title === 'string' ? title : title.uuid
        const cacheKey = ['title.seasons', uuid]
        if (cache.has(cacheKey)) return cache.get<Season[]>(cacheKey)

        const {data} = await http.get<ApiData>(placeholderReplacement(Endpoints.MOVIE_SEASONS, {'title': uuid}))
        data && cache.set(cacheKey, data)

        return data
    },
    async fetchVideo(type: string, title: Title | string, season?: Season | string, episode?: Episode | string) {
        const titleUuid = typeof title === 'string' ? title : title.uuid
        const seasonUuid = typeof season === 'string' ? season : season?.uuid
        const episodeUuid = typeof episode === 'string' ? episode : episode?.uuid
        let urlReplacement = {'title': titleUuid, 'type': type}
        if (seasonUuid) {
            if (typeof episodeUuid == undefined) {
                throw new Error(`Can't fetch without episode`)
            }
            urlReplacement = {...urlReplacement, ...{'season': seasonUuid, 'episode': episodeUuid}};
        }
        const {data} = await http.get<ApiData>(placeholderReplacement(Endpoints.MOVIE_VIDEO_FETCH, urlReplacement, false))

        return data ? JSON.parse(decodeURIComponent(window.atob(data as any))) : null;
    },
}
