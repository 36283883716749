import {defineAsyncComponent, h} from "vue";
import {eventBus, noop} from '@/libs/utils'
import type {OverlayState} from "@/libs/types/overlayState";


export interface KeyB {
    [x: string]: string | boolean| Record<string, unknown>;
}

/*
A factory function to create a dynamic route
which is a functional component
with its immediate child the async component
*/
export const lazyRoute = (f: any) => () => Promise.resolve(() => h(defineAsyncComponent(f)));


/**
 * Force reloading window regardless of "Confirm before reload" setting.
 * This is handy for certain cases, for example Last.fm connect/disconnect.
 */
export const forceReloadWindow = (): void => {
    if (process.env.NODE_ENV === 'test') {
        return
    }

    window.onbeforeunload = noop
    window.location.reload()
}

/**
 * Sequential ID generator
 */
export const Id = (i => () => i++)(0);

/**
 * Checks whether an element is visible
 */
export const isVisible = (element: HTMLElement) => {
    if (!(element instanceof Element)) {
        throw Error('You must provide a DOM element.')
    }

    return (
        !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length) &&
        window.getComputedStyle(element).visibility !== 'hidden' &&
        window.getComputedStyle(element).display !== 'none'
    )
}

/**
 * Return the previous/next element of a list.
 */
export const getNextActiveElement = (
    list: HTMLElement[],
    activeElement: HTMLElement,
    shouldGetNext: boolean,
    isCycleAllowed: boolean
) => {
    const listLength = list.length
    let index = list.indexOf(activeElement)

    // if the element does not exist in the list return an element
    // depending on the direction and if cycle is allowed
    if (index === -1) {
        return !shouldGetNext && isCycleAllowed ? list[listLength - 1] : list[0]
    }

    index += shouldGetNext ? 1 : -1

    if (isCycleAllowed) {
        index = (index + listLength) % listLength
    }

    return list[Math.max(0, Math.min(index, listLength - 1))]
}

export const showOverlay = (
    message = 'Just a little patience…',
    type: OverlayState['type'] = 'loading',
    dismissible = false
) => eventBus.emit('SHOW_OVERLAY', {message, type, dismissible})

export const hideOverlay = () => eventBus.emit('HIDE_OVERLAY')

export const placeholderReplacement = (stringWithPlaceholders: string, replacements: any, returnEmptyPlaceholder: boolean = true) => {

    return stringWithPlaceholders.replace(
        /{(\w+)}/g,
        (placeholderWithDelimiters, placeholderWithoutDelimiters) =>
            replacements.hasOwnProperty(placeholderWithoutDelimiters) ?
                replacements[placeholderWithoutDelimiters] : returnEmptyPlaceholder ? placeholderWithDelimiters : ''
    );
}

export const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

// Helper function
export const domReady = (cb: any) => {
    document.readyState === 'interactive' || document.readyState === 'complete'
        ? cb()
        : document.addEventListener('DOMContentLoaded', cb);
};

export const jsonDecode = (data: any) => {
    const decodedData = decodeURIComponent(
        Buffer.from(data, 'base64').toString().replace(/\+/g, ' '),
    );
    return JSON.parse(decodedData);
}

// https://stackoverflow.com/questions/35948669/how-to-check-if-a-value-exists-in-an-object-using-javascript
export const checkIfExistingValue = (obj: any, key: any, value: any) => {
    return obj.hasOwnProperty(key) && obj[key] === value;
}

export const debounce = (fn: Function, delay: number = 0, immediate: boolean = false) => {
    type Timer = ReturnType<typeof setTimeout>
    let timeout: Timer;
    return (...args: string[]) => {
        if (immediate && !timeout){
            fn(...args);
        }
        clearTimeout(timeout);

        timeout = setTimeout(() =>{
            fn(...args);
        }, delay);
    }
}

export function isUndefined (value: undefined) {
    return typeof value === 'undefined'
}

export function isDefined (value: string[]) {
    return typeof value !== 'undefined'
}

export function isObject (value: Record<string, unknown>) {
    return value !== null && typeof value === 'object'
}

export function isString (value: string) {
    return typeof value === "string"
}

export function isNumber (value: number) {
    return typeof value === 'number'
}

/**
 * Assemble url from two segments
 *
 * @author Sahat Yalkabov <https://github.com/sahat>
 * @copyright Method taken from https://github.com/sahat/satellizer
 *
 * @param  {String} baseUrl Base url
 * @param  {String} url     URI
 * @return {String}
 */
export function joinUrl (baseUrl: string, url: string): string {
    if (/^(?:[a-z]+:)?\/\//i.test(url)) {
        return url
    }
    const joined = [baseUrl, url].join('/')
    const normalize = function (str: string) {
        return str
            .replace(/[/]+/g, '/')
            .replace(/\/\?/g, '?')
            .replace(/\/#/g, '#')
            .replace(/:\//g, '://')
    }
    return normalize(joined)
}

/**
 * Get full path based on current location
 *
 * @author Sahat Yalkabov <https://github.com/sahat>
 * @copyright Method taken from https://github.com/sahat/satellizer
 *
 * @param  {Location} location
 * @return {String}
 */
export function getFullUrlPath (location: Location | HTMLAnchorElement): string {
    const isHttps = location.protocol === 'https:'
    return (
        location.protocol +
        '//' +
        location.hostname +
        ':' +
        (location.port || (isHttps ? '443' : '80')) +
        (/^\//.test(location.pathname)
            ? location.pathname
            : '/' + location.pathname)
    )
}

/**
 * Parse query string variables
 *
 * @author Sahat Yalkabov <https://github.com/sahat>
 * @copyright Method taken from https://github.com/sahat/satellizer
 *
 * @return {String | Boolean}
 * @param str
 */
export function parseQueryString (str: string): KeyB {
    const obj: KeyB = {}
    let key
    let value;
    (str || '').split('&').forEach((keyValue: string) => {
        if (keyValue) {
            value = keyValue.split('=')
            key = decodeURIComponent(value[0])
            obj[key] = value[1] ? decodeURIComponent(value[1]) : true
        }
    })
    return obj
}
// export const envValue = () =>{
//     return import.meta.env;
// }