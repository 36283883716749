import {commonStore, localStorageStore, userStore} from "@/stores";
import {eventBus} from "@/libs/utils";
import {localStorageService} from "@/services";
//import Cookies from "js-cookie";

export default async (to: any, from: any, next: any) => {
    // let loggedInAndNotLoggedInRouteArray = [
    //   "verification.confirm",
    //   "verification.resend"
    // ];
    // let unAuthenticatedRoutes = [
    //   "landing",
    //   "auth-wall",
    //   "register",
    //   "forgot-password",
    //   "forgot-password.reset"
    // ];
    // const requiresNoAuthenticationRoutes = unAuthenticatedRoutes.includes(
    //   to.name
    // );
    // const loggedInAndNotLoggedInRoute = loggedInAndNotLoggedInRouteArray.includes(
    //   to.name
    // );
    if (typeof to.matched[0]?.components.default === "function") {
        eventBus.emit("asyncComponentLoading", to); // Start progress bar
    }
    // console.log(localStorageStore.hasToken() && !userStore.isLoggedIn())
    //if (localStorageStore.hasToken() && !userStore.isLoggedIn()) {
    // try {
    //   // await userStore.init(await userStore.getUser());
    //   await commonStore.init();
    //   // eslint-disable-next-line no-empty
    // } catch (e) {}
    // }
    const inMaintenanceMode = localStorageService.get('maintenanceMode');
    const isSystemError = localStorageService.get('systemError');
    if (inMaintenanceMode && to.name !== 'maintenance') {
        (window as Window).location = 'maintenance';
    }
    if (isSystemError && to.name !== 'system-error') {
        (window as Window).location = 'system-error';
    }

    if (to.meta.onlyAuthenticated) {
        if (userStore.isLoggedIn() || localStorageStore.hasToken()) {
            return next();
        } else {
            return next({name: "auth-wall", query: {"redirect": to.fullPath}});
        }
    }
    //othGuestAndAuthenticated
    // if (to.meta.onlyGuest) {
    //     if (userStore.isLoggedIn() || localStorageStore.hasToken()) {
    //         return next({name: "browse"});
    //     } else {
    //         return next();
    //     }
    // }
    if (to.meta.onlyGuest) {
        if (userStore.isLoggedIn() || localStorageStore.hasToken()) {
            return next({name: "browse"});
        } else {
            return next();
        }
    }
    next();
    /**
     * IF THE USER IS NOT LOGGED IN
     */
    // if (!userStore.isLoggedIn()) {
    //   // Cookies.set("intended_url", to.path);
    //   if (requiresNoAuthenticationRoutes || loggedInAndNotLoggedInRoute) {
    //     next(); // The user is not logged in, but it's going to exceptional routes ? fine
    //     return;
    //   }
    //   // other routes than exceptional paths => /login
    //   next({ name: "auth-wall", query: { "redirect-to": to.fullPath } });
    //   return;
    // }

    /**
     * IF THE USER IS LOGGED IN
     */
    // if (requiresNoAuthenticationRoutes) {
    //   next({ name: "browse" });
    //   return;
    // }
    // next();
};
