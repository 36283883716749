import {merge} from 'lodash'
import type {UnwrapNestedRefs} from 'vue'
import {reactive} from 'vue'
import {arrayify, logger, placeholderReplacement, use} from '@/libs/utils'
import {cache, http} from '@/services'
import {Endpoints} from "@/libs/EndpointConstant";
import type {ApiData} from "@/libs/types/apiData";
import type {Plan} from "@/libs/types/plan";

export const planStore = {
    vault: new Map<string, UnwrapNestedRefs<Plan>>(),

    state: reactive({
        plans: [] as Plan[]
    }),

    byUuid(uuid: string | null) {
        return this.vault.get(uuid as string)
    },

    byUuids(uuids: string[]) {
        const plans = [] as Plan[]
        uuids.forEach(uuid => use(this.byUuid(uuid), Plan => plans.push(Plan!)))
        return plans
    },

    async resolve(uuid: string | null) {
        let plan = this.byUuid(uuid)

        if (!plan) {
            try {
                plan = this.syncWithVault(await http.get<Plan>(placeholderReplacement(Endpoints.MOVIE_INFO, {'uuid': uuid})))[0]
            } catch (e: any) {
                logger.error(e)
            }
        }

        return plan
    },

    syncWithVault(plans: Plan | Plan[]) {
        return arrayify(plans).map(Plan => {
            let local = this.byUuid(Plan.uuid)

            if (local) {
                merge(local, Plan)
            } else {
                local = reactive(Plan)
                this.vault.set(local.uuid, local)
            }

            return local
        })
    },
    async cacheable(key: any, fetcher: Promise<Plan[]>) {
        return await cache.remember<Plan[]>(key, async () => this.syncWithVault(await fetcher))
    },

    async fetchPlans() {
        const cacheKey = ['billing.plans']
        if (cache.has(cacheKey)) return cache.get<Plan[]>(cacheKey)

        const {data} = await http.get<ApiData>(Endpoints.BILLING_PLANS)
        data && cache.set(cacheKey, data)

        return data
    },
}
