export default [
  {
    path: "/",
    name: "landing",
    component: () => import("@/pages/public/content/LandingPage.vue"),
    meta: {
      onlyGuest: true,
      layout: "PublicLayout"
    }
  },
  {
    name: "auth-wall",
    path: "/auth-wall",
    component: () => import("@/pages/public/auth/LoginPage.vue"),
    meta: {
      onlyGuest: true,
      layout: "AuthLayout"
    }
  },
  {
    name: "register",
    path: "/register",
    component: () => import("@/pages/public/auth/RegisterPage.vue"),
    meta: {
      onlyGuest: true,
    }
  },
  {
    name: "verification.confirm",
    path: "/verify/confirm/:confirmationToken",
    component: () => import("@/pages/public/auth/VerificationPage.vue"),
    meta: {
      othGuestAndAuthenticated: true,
    }
  },
  {
    name: "verification.resend",
    path: "/verify/resend",
    component: () => import("@/pages/public/auth/VerificationPage.vue"),
    meta: {
      bothGuestAndAuthenticated: true,
      layout: "AuthLayout"
    }
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/pages/public/auth/ForgetPasswordPage.vue"),
    meta: {
      onlyGuest: true,
      layout: "AuthLayout"
    },
    children: [
      {
        path: "reset/:token",
        name: "forgot-password.reset",
        component: () => import("@/pages/public/auth/ResetPasswordPage.vue")
      }
    ]
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("@/pages/public/content/PrivacyPolicyPage.vue"),
    meta: {
      othGuestAndAuthenticated: true,
      layout: "AuthLayout"
    }
  },
  {
    path: "/terms-of-use",
    name: "terms-of-use",
    component: () => import("@/pages/public/content/TermsOfUsePage.vue"),
    meta: {
      othGuestAndAuthenticated: true,
      layout: "AuthLayout"
    }
  }
];
