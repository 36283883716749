import {createApp, markRaw} from "vue";
import {createMetaManager} from "vue-meta";
import { VueReCaptcha } from 'vue-recaptcha-v3'
// import RouterPrefetch from 'vue-router-prefetch';
import VueLazyload from "@jambonn/vue-lazyload";
import App from "./App.vue";
import rate from "vue-rate";
import router from "@/routes";
import "vue-rate/dist/vue-rate.css";
import {FontAwesomeIcon, FontAwesomeLayers} from "@fortawesome/vue-fontawesome";
import {clickaway, focus, hideBrokenIcon, tooltip} from "@/directives";
import {domReady} from "@/libs/utils";
import {overflowFade} from "@/directives/overflowFade";
import {initSentry} from "@/libs/sentry";

// const pinia = createPinia();
// pinia.use(({store}) => {
//     store.$router = markRaw(router);
// });
domReady(() => {
    // Display body when DOM is loaded
    document.body.style.visibility = "visible";
});
const app = createApp(App);
initSentry(app, router);
app
    .use(router)
    .component("icon", FontAwesomeIcon)
    .component("icon-layers", FontAwesomeLayers)
    .directive("ofilms-focus", focus)
    .directive("ofilms-clickaway", clickaway)
    .directive("ofilms-tooltip", tooltip)
    .directive("ofilms-hide-broken-icon", hideBrokenIcon)
    .directive('ofilms-overflow-fade', overflowFade)
    .use(rate)
    .use(VueLazyload, {
        observer: true,
        preLoad: 1.3,
        attempt: 1
    })
    .use(VueReCaptcha, {loaderOptions: {autoHideBadge: true}, siteKey: import.meta.env.OFILMS_GOOGLE_RECAPTCHA_PUBLIC_KEY})
    .use(createMetaManager())
    .mount("#app");
