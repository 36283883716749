import {reactive} from "vue";

export const loadingStore = {
    state: reactive({
        loading: false,
        requestsPending: 0,
    }),

    show() {
        this.state.loading = true;
    },
    hide() {
        this.state.loading = false;
    },
    pending() {
        if (this.state.requestsPending === 0) {
            this.show();
        }
        this.state.requestsPending++;
    },
    done() {
        if (this.state.requestsPending >= 1) {
            this.state.requestsPending--;
        }

        if (this.state.requestsPending <= 0) {
            this.hide();
        }
    }
}
