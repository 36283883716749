/**
 * initial custom event
 * When you want to put multiple events in the duplicate `dom' (especially window),
 * it is used to create and use a custom event to create an event with a unique name.
 */
export function customEvent() {
  const events = {
    on(event, cb, opts = false) {
      if (!this.namespaces) this.namespaces = {};
      this.namespaces[event] = cb;
      const options = opts || false;
      this.addEventListener(event.split(".")[0], cb, options);
      return this;
    },
    off(event) {
      if (!(this.namespaces && this.namespaces[event])) return;
      this.removeEventListener(event.split(".")[0], this.namespaces[event]);
      delete this.namespaces[event];
      return this;
    }
  };
  window.on = document.on = Element.prototype.on = events.on;
  window.off = document.off = Element.prototype.off = events.off;
}
