/**
 * Global event listeners (basically, those without a Vue instance access) go here.
 */
import {defineComponent, onMounted} from "vue";
import {authService} from "@/services";
import {eventBus, forceReloadWindow} from "@/libs/utils";
import {useRouter} from "vue-router";
import {commonStore, userStore} from "@/stores";
import type {useMessageToaster} from "@/composables/useMessageToaster";

export const GlobalEventListeners = defineComponent({
    setup(props, {slots}) {
        // eslint-disable-next-line no-unused-vars
        let toastSuccess: ReturnType<typeof useMessageToaster>["toastSuccess"];
        // eslint-disable-next-line no-unused-vars
        //let showConfirmDialog: ReturnType<typeof useDialogBox>["showConfirmDialog"];
        const router = useRouter();

        onMounted(() => {
            //toastSuccess = useMessageToaster().toastSuccess;
            //showConfirmDialog = useDialogBox().showConfirmDialog;
        });

        eventBus.on({
            AUTHENTICATE: async () => {
                //if (!commonStore.state.banners.length) {
                await commonStore.init();
                //}
                if (userStore.state.returnUrl != null) {
                    await router.push(userStore.state.returnUrl);
                } else {
                    await router.push({name: "browse"});
                }
            },

            /**
             * Log the current user out and reset the application state.
             */
            LOG_OUT: async () => {
                try {
                    await userStore.logout();
                    authService.destroy();
                    forceReloadWindow();
                } catch (e) {
                    authService.destroy();
                    forceReloadWindow();
                }
            }
        });

        return () => slots.default?.();
    }
});
