import type {UnwrapNestedRefs} from 'vue'
import {reactive} from 'vue'
import {differenceBy, unionBy} from 'lodash'
import {cache, http} from '@/services'
import {arrayify, logger, placeholderReplacement} from "@/libs/utils";
import type {Person} from "@/libs/types/person";
import type {ApiData} from "@/libs/types/apiData";
import {Endpoints} from "@/libs/EndpointConstant";

export const personStore = {
    vault: new Map<string, UnwrapNestedRefs<Person>>(),

    state: reactive({
        persons: [] as Person[]
    }),

    byUuid(uuid: string) {
        return this.vault.get(uuid)
    },

    removeByUuids(uuids: string[]) {
        this.state.persons = differenceBy(this.state.persons, uuids.map(uuid => this.byUuid(uuid)), 'uuid')
        uuids.forEach(uuid => this.vault.delete(uuid))
    },

    syncWithVault(persons: Person | Person[]) {
        return arrayify(persons).map(person => {
            let local = this.vault.get(person.uuid)
            local = local ? Object.assign(local, person) : reactive(person)
            this.vault.set(person.uuid, local)

            return local
        })
    },
    creditsCount(uuid: string): number {
        let person = this.byUuid(uuid);
        if (typeof person?.credits !== 'undefined') {
            return Object.keys(person?.credits as object).map(department => {
                return person!.credits![department].length;
            }).reduce((a, b) => a + b, 0);
        }
        return 0;
    },
    async resolve(uuid: string) {
        // let person = this.byUuid(uuid)
        //
        // if (!person) {
        try {
            return this.syncWithVault(
                await cache.remember<Person>(['person', uuid], async () => await http.get<Person>(placeholderReplacement(Endpoints.PEOPLE_SINGLE, {'person': uuid})))
            )[0]
        } catch (e) {
            logger.error(e as any)
        }
        //}

        //return person
    },

    async paginate(page: number, mostPopular?: boolean, order: string = 'popularity:desc') {
        //return await cache.remember<number | null>(["person-list"], async () => {
        const resource = await http.get<ApiData>(Endpoints.PEOPLE_ALL, {
            params: {
                page: page,
                mostPopular: mostPopular,
                order
            }
        })
        this.state.persons = unionBy(this.state.persons, this.syncWithVault(resource.data), 'uuid')
        return resource.links.next ? ++resource.meta.current_page : null
        //});
    }
}
