<template>
  <Transition name="fade">
    <div v-if="isOpen" class="fixed inset-0 w-full h-screen flex items-center justify-center bg-opacity-50 bg-black z-50">
      <component :is="view"></component>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { modalStore } from "@/stores";
import { toRefs } from "vue";

// convert all state properties to reactive references to be used on view
const { isOpen, view } = toRefs(modalStore.state);

</script>


<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: all 0.4s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>