import * as Dot from 'dot-object';
import type {AppConfig} from "@/libs/types/app-config";
import {reactive} from "vue";

const configs = reactive<AppConfig>({});
export function useSettings() {

  const setMultiple = (settings: AppConfig) => {
    if (!settings) return;
    const newConfig = {...configs.value};
    Object.entries(settings).forEach(([key, value]) => {
      if (value === '0' || value === '1') {
        value = parseInt(value);
      }
      Dot.set(key, value, newConfig);
    });
    configs.value = newConfig;
  }

  const get = (name: keyof AppConfig | any, defaultValue: any = null): any => {
    const value = Dot.pick(name, configs.value);
    return value == null ?defaultValue : value
  }

  const getFlat = (name?: string) => {
    return Dot.dot(name ? configs.value[name] : configs.value);
  }

  const getAll = () => {
    return configs.value;
  }

  const has = (name: keyof AppConfig): boolean => {
    return !!Dot.pick(name as string, configs.value);
  }

  /**
   * Get a json setting by key and decode it.
   */
  const getJson = (name: keyof AppConfig, defaultValue: any = null) => {
    const value = get(name, defaultValue);
    if (typeof value !== "string") return value;
    return JSON.parse(value);
  }

  /**
   * Get base url for the app.
   */
  const getBaseUrl = (forceServerUrl = false): string => {
    // sometimes we might need to get base url supplied by backend
    // even in development environment, for example, to prevent
    // uploaded images from having proxy urls like "localhost:4200"
    if (
      has("base_url") &&
      (get("ofilms.environment") === "production" || forceServerUrl)
    ) {
      return get("base_url");
    } else if (document.querySelector("base")) {
      // @ts-ignore
      return document.querySelector("base").href.replace(/\/$/, "");
    } else {
      // 'https://site.com/subdomain/index.html/" => 'https://site.com/subdomain/'
      const url = window.location.href.split("?")[0];
      return url.replace(/([^/]+\.\w+($|\/$))/, "");
    }
  }

  /**
   * Get app's asset base url.
   */
  const getAssetUrl = (suffix?: string, forceServerUrl = false): string => {
    let uri = (get("ofilms.assetsUrl") || getBaseUrl(forceServerUrl)) + "/";
    const prefix = get("ofilms.assetsPrefix");

    // in production assets will be in "client" sub-folder
    if (get("ofilms.environment") === "production" && prefix) {
      uri += prefix + "/";
    }

    uri += "assets/";

    if (suffix) uri += suffix;

    return uri;
  }

  const anySocialLoginEnabled = (): boolean => {
    const names = ["facebook", "google", "twitter"];
    return names.some(name => get("social." + name + ".enable"));
  }

  return {anySocialLoginEnabled, has, getBaseUrl, getAssetUrl, get, getAll, getFlat, getJson, setMultiple, }
}
