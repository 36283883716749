const { OFILMS_API_HOST } = import.meta.env;

export const Endpoints = {
  DATA: `${OFILMS_API_HOST}/data`,
  LOGIN: `${OFILMS_API_HOST}/auth/login`,
  SOCIAL_CONNECT: `${OFILMS_API_HOST}/auth/social/{provider}/connect`,
  SOCIAL_LOGIN: `${OFILMS_API_HOST}/auth/social/{provider}/login`,
  SOCIAL_CALLBACK: `${OFILMS_API_HOST}/auth/social/{provider}/callback`,
  SOCIAL_DISCONNECT: `${OFILMS_API_HOST}/auth/social/{provider}/disconnect`,
  SOCIAL_RETRIEVE_PROFILE: `${OFILMS_API_HOST}/auth/social/{provider}/retrieve-profile`,
  SOCIAL_EXTRA_CREDENTIALS: `${OFILMS_API_HOST}/auth/social/extra-credentials`,

  REGISTER: `${OFILMS_API_HOST}/register`,
  VERIFICATION_CONFIRMATION: `${OFILMS_API_HOST}/verification/confirm`,
  VERIFICATION_RESEND: `${OFILMS_API_HOST}/verification/resend`,
  LOGOUT: `${OFILMS_API_HOST}/account/logout`,
  ME: `${OFILMS_API_HOST}/account/me`,
  POST_REFRESH_TOKEN: `${OFILMS_API_HOST}/auth/refresh`,
  GET_REFRESH_TOKEN: `${OFILMS_API_HOST}/refresh`,
  PASSWORD_REMINDER: `${OFILMS_API_HOST}/password/reminder`,
  PASSWORD_RESET: `${OFILMS_API_HOST}/password/reset`,
  ACCOUNT_BASIC_UPDATE: `${OFILMS_API_HOST}/account/update_basic`,
  ACCOUNT_PASSWORD_UPDATE: `${OFILMS_API_HOST}/account/update_password`,
  ACCOUNT_TRANSACTIONS: `${OFILMS_API_HOST}/account/transactions`,
  ACCOUNT_AVATAR_UPLOAD: `${OFILMS_API_HOST}/account/avatar/upload`,
  ACCOUNT_AVATAR_REMOVE: `${OFILMS_API_HOST}/account/avatar/remove`,

  BANNERS: `${OFILMS_API_HOST}/content/banners`,
  LIST: `${OFILMS_API_HOST}/movies/list`,
  LIST_ITEMS: `${OFILMS_API_HOST}/movies/list/{list}/items`,

  MOVIE_INFO: `${OFILMS_API_HOST}/movies/titles/{uuid}`,
  MOVIE_RELATED: `${OFILMS_API_HOST}/movies/titles/{uuid}/related`,
  MOVIE_BACKSTAGE: `${OFILMS_API_HOST}/movies/titles/{uuid}/back-set`,
  MOVIE_RATE: `${OFILMS_API_HOST}/movies/titles/{uuid}`,
  MOVIE_SEASONS: `${OFILMS_API_HOST}/movies/titles/{title}/seasons`,
  MOVIE_EPISODES: `${OFILMS_API_HOST}/movies/titles/{title}/seasons/{season}/episodes`,
  MOVIE_VIDEO_FETCH: `${OFILMS_API_HOST}/movies/videos/fetch/{type}/{title}/{season}/{episode}`,
  MOVIE_VIDEO_FETCH_CAPTION: `${OFILMS_API_HOST}/movies/videos/captions/{caption}`,

  FETCH_WATCHLIST: `${OFILMS_API_HOST}/account/watch-list`,
  ADD_WATCHLIST: `${OFILMS_API_HOST}/account/watch-list/add`,
  REMOVE_WATCHLIST: `${OFILMS_API_HOST}/account/watch-list/remove`,

  REVIEW_LIST: `${OFILMS_API_HOST}/content/reviews/{reviewableType}/{reviewableId}`,
  REVIEW_ADD: `${OFILMS_API_HOST}/content/reviews/add`,
  REVIEW_REMOVE: `${OFILMS_API_HOST}/content/reviews/remove`,

  BILLING_PLANS: `${OFILMS_API_HOST}/billing/plans`,
  BILLING_PLAN_CONVERT_NAIRA: `${OFILMS_API_HOST}/billing/plans/convert`,
  BILLING_PAYMENT_COMPLETE: `${OFILMS_API_HOST}/billing/payment/{gateway}/complete`,
  BILLING_PAYMENT_AUTHORIZE: `${OFILMS_API_HOST}/billing/payment/{gateway}/authorize`,
  BILLING_SUBSCRIPTION_CANCEL: `${OFILMS_API_HOST}/billing/subscription/cancel`,
  BILLING_SUBSCRIPTION_RESUME: `${OFILMS_API_HOST}/billing/subscription/resume`,
  BILLING_PAYMENT_METHOD_ADD: `${OFILMS_API_HOST}/account/payment-method/add`,
  BILLING_PAYMENT_STRIPE_GET_INTENT: `${OFILMS_API_HOST}/billing/payment/stripe-manage/create-intent`,

  NEWS_ALL: `${OFILMS_API_HOST}/content/news/list`,
  NEWS_SINGLE: `${OFILMS_API_HOST}/content/news/{article}`,
  NEWS_SINGLE_RELATED: `${OFILMS_API_HOST}/content/news/{article}/related`,

  PEOPLE_ALL: `${OFILMS_API_HOST}/content/people/list`,
  PEOPLE_SINGLE: `${OFILMS_API_HOST}/content/people/{person}`,

  CONTENT_SEARCH: `${OFILMS_API_HOST}/content/search`,
  SUPPORT_SEND_CONTACT_MESSAGE: `${OFILMS_API_HOST}/support/send`,
};
