import type {UnwrapNestedRefs} from 'vue'
import {reactive} from 'vue'
import {merge} from 'lodash'
import {cache, http} from '@/services'
import {arrayify, logger, placeholderReplacement} from '@/libs/utils'
import type {List} from "@/libs/types/list";
import {Endpoints} from "@/libs/EndpointConstant";
import type {ApiData} from "@/libs/types/apiData";
import type {Title} from "@/libs/types/title";

export const listStore = {
    vault: new Map<string, UnwrapNestedRefs<List>>(),

    state: reactive({
        lists: [] as List[],
        listItems: [] as Title[]
    }),

    byUuid(uuid: string) {
        return this.vault.get(uuid)
    },

    syncWithVault(lists: List | List[]) {
        return arrayify(lists).map(list => {
            let local = this.vault.get(list.uuid)
            local = reactive(local ? merge(local, list) : list)
            this.vault.set(list.uuid, local)

            return local
        })
    },

    async resolve(uuid: string) {
        let list = this.byUuid(uuid)

        if (!list) {
            try {
                list = this.syncWithVault(
                    await cache.remember<List>(['list', uuid], async () => await http.get<List>(Endpoints.LIST))
                )[0]
            } catch (e: any) {
                logger.error(e)
            }
        }

        return list
    },

    fetchBanners: async () => {
        const {data} = await http.get<ApiData>(Endpoints.BANNERS)

        return data;
    },

    fetchLists: async (type: string | undefined | null, genre?: string | undefined) => {
        return await cache.remember<List[]>(["browse-list", type, genre], async () => {
            const {data} = await http.get<ApiData>(Endpoints.LIST, {
                params: {
                    type,
                    genre
                }
            })
            return data;
        });
    },

    fetchListItems: async (list: List | string, type: string | undefined, genre?: string | undefined) => {
        const uuid = typeof list === 'string' ? list : list.uuid
        return await cache.remember<Title[]>(["browse-list-items", uuid, type, genre], async () => {
            const {data} = await http.get<ApiData>(placeholderReplacement(Endpoints.LIST_ITEMS, {'list': uuid}), {
                params: {
                    type,
                    genre
                }
            });
            return data ?? [];
        })
    },
}
