import type {RouteLocationNormalized, RouteLocationNormalizedLoaded, RouteRecordRaw} from 'vue-router'
import {createRouter, createWebHistory} from 'vue-router'
import publicRoute from "@/routes/publicRoute";
import privateRoute from '@/routes/privateRoute'
import beforeEachMiddleware from "@/routes/middleware/beforeEachMiddleware";
import type {Title} from "@/libs/types/title";
import {eventBus} from "@/libs/utils";
import maintenanceMiddleware from "@/routes/middleware/maintenanceMiddleware";
import systemErrorMiddleware from "@/routes/middleware/systemErrorMiddleware";
//import {useUserStore} from "@/stores/user";

const routes: Array<RouteRecordRaw> = [
    ...publicRoute,
    ...privateRoute,
    {
        path: '/maintenance',
        name: 'maintenance',
        component: () => import('@/components/shared/MaintenanceMode.vue'),
        meta: {
            layout: 'BlankLayout'
        },
        beforeEnter: [maintenanceMiddleware]
    },
    {
        path: '/system-error',
        name: 'system-error',
        component: () => import('@/components/shared/SystemError.vue'),
        meta: {
            layout: 'BlankLayout'
        },
        beforeEnter: [systemErrorMiddleware]
    },
    {
        path: '/:catchAll(.*)*',
        name: 'NotFound',
        component: () => import('@/components/shared/NotFound.vue'),
        meta: {
            layout: 'PublicLayout'
        }
    }
];
const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    scrollBehavior(to: RouteLocationNormalized, __: RouteLocationNormalizedLoaded, savedPosition: any) {
        if (to.query.scrollTop) {
            return {left: 0, top: to?.query.scrollTop};
        }

        if (savedPosition) {
            return savedPosition;
        }

        return {left: 0, top: 0};
    },
});

router.beforeEach(beforeEachMiddleware);
router.beforeResolve((to, from, next) => {
    eventBus.emit('asyncComponentLoaded') // Stop progress bar
    next()
})

export default router;

declare module 'vue-router' {
    export interface RouteMeta {
        title?: Title
    }
}