import {reactive} from 'vue'
import {authService} from "@/services";
export const localStorageStore = {
    state: reactive({
        token: '' as string
    }),

    getToken: () => authService.getToken(),

    hasToken() {
        return Boolean(this.getToken());
    },

    setToken: (token: string) => authService.setToken(token),
    destroy: () => authService.destroy()
}
