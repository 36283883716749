import type {DeepReadonly, InjectionKey, Ref} from 'vue'
import type DialogBox from "@/components/shared/Utils/DialogBox.vue";
import type MessageToaster from '@/components/shared/Utils/MessageToaster.vue'
import type {User} from "@/libs/types/user";

// eslint-disable-next-line no-undef
export interface ReadonlyInjectionKey<T> extends InjectionKey<[Readonly<T> | DeepReadonly<T>, Closure]> {
}
export const UserKey: ReadonlyInjectionKey<Ref<User>> = Symbol('User')
export const DialogBoxKey: InjectionKey<Ref<InstanceType<typeof DialogBox>>> = Symbol('DialogBox')

export const MessageToasterKey: InjectionKey<Ref<InstanceType<typeof MessageToaster>>> = Symbol('MessageToaster')

