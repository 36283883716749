import { userStore } from "@/stores";

export default async (to: any, from: any, next: any) => {
  await userStore.init(await userStore.getUser());
  if (!userStore.isSubscribed()) {
    next({ name: "plans" });
    return;
  }
  next();
};
