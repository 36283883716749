import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

export const initSentry = (app: any, router: any) => {
    if (!window.location.host.includes('home.oraclefilms.chistel.website')) {
        Sentry.init({
            app,
            dsn: "https://f3d08c7ea8254e3899c747f34b4100a6@o4504486884409344.ingest.sentry.io/4504486887620608",
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                    //tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
                }),
            ],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
        });
    }
}