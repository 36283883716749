import {merge} from 'lodash'
import {http} from '@/services'
import {reactive} from 'vue'
import {arrayify} from '@/libs/utils'
import type {UnwrapNestedRefs} from '@vue/reactivity'
import type {User} from "@/libs/types/user";
import {Endpoints} from "@/libs/EndpointConstant";

export const userStore = {
    vault: new Map<string, UnwrapNestedRefs<User>>(),

    state: reactive({
        users: [] as User[],
        current: null as unknown as User,
        returnUrl: null as unknown as string
    }),

    syncWithVault(users: User | User[]) {
        return arrayify(users).map(user => {
            let local = this.byUuid(user.uuid)
            local = reactive(local ? merge(local, user) : user)
            this.vault.set(user.uuid, local)

            return local
        })
    },

    init(currentUser: User) {
        this.state.users = this.syncWithVault(currentUser)
        this.state.current = this.state.users[0]
    },

    byUuid(uuid: string) {
        return this.vault.get(uuid)
    },

    get current() {
        return this.state.current
    },

    isLoggedIn(): boolean {
        return this.state.current != null;
    },

    /**
     * Check if user subscription is active, on trial, or on grace period.
     */
    isSubscribed(): boolean {
        return this.state?.current?.activeSubscription != null
    },

    canResumeSubscription(): undefined | boolean {
        return this.onGracePeriod();
    },

    canCancelSubscription(): boolean {
        return this.isSubscribed() && !userStore.onGracePeriod();
    },

    /**
     * Check if user subscription is active
     */
    subscriptionIsActive(): boolean {
        return this.isSubscribed();
    },

    onTrial() {
        const sub = this.state.current.activeSubscription;
        return sub && sub.onTrial;
    },

    onGracePeriod(): undefined | boolean {
        const sub = this.state.current.activeSubscription;
        return sub && sub.onGracePeriod;
    },

    logout: async () => await http.delete(Endpoints.LOGOUT),
    postRefreshAccessToken: async () => {
        const response = await http.post(Endpoints.POST_REFRESH_TOKEN);
        // @ts-ignore
        return response.data;
    },
    getRefreshAccessToken: async () => {
        return await http.get(Endpoints.GET_REFRESH_TOKEN);
    },
    getUser: async () => {
        return await http.get<User>(Endpoints.ME);
    },
}
