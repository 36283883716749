import {markRaw, reactive} from "vue";
import {merge} from 'lodash'

export type ModalAction = {
    label: string;
    callback: (props?: any) => void;
};
export type Modal = {
    isOpen: boolean;
    view: object;
    actions?: ModalAction[];
};

export const modalStore = {
    state: reactive<Modal>({
        isOpen: false,
        view: {},
        actions: [] as ModalAction[]
    }),
    open(view: object, actions?: ModalAction[]) {
        this.state.isOpen = true;
        this.state.actions = actions;
        // using markRaw to avoid over performance as reactive is not required
        this.state.view = markRaw(view);
    },
    close() {
        this.state.isOpen = false;
        this.state.view = {};
        this.state.actions = [];
    }
};
