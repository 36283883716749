<template>
	<DialogBox ref="dialog"/>
	<MessageToaster ref="toaster"/>
	<GlobalEventListeners/>
	<ModalWrapper/>
	<!--   <OfflineNotification v-if="offline"/>-->
	<metainfo>
		<template v-slot:title="{ content }"
		>{{
				content
					? `${content} | ${env?.OFILMS_APP_TITLE}`
					: `${env?.OFILMS_APP_TITLE}`
			}}
		</template>
	</metainfo>
	<LoadingIndicator v-if="loadingStore.state.loading"/>
	<AppLayout v-else>
		<RouteProgressBar></RouteProgressBar>
		<router-view/>
	</AppLayout>
</template>

<script lang="ts" setup>
import {defineAsyncComponent, nextTick, onBeforeMount, onMounted, provide, ref} from "vue";
import {useMeta} from "vue-meta";
import {GlobalEventListeners} from "@/components/shared/Utils/GlobalEventListeners";
import {DialogBoxKey, MessageToasterKey} from "@/libs/symbol";
import {customEvent} from "@/libs/utils/";
import {useNetworkStatus} from "@/composables/useNetworkStatus";
import MessageToaster from "@/components/shared/Utils/MessageToaster.vue";
import ModalWrapper from "@/components/modal/ModalWrapper.vue";
import RouteProgressBar from "@/components/shared/Utils/RouteProgressBar.vue";
import {loadingStore} from "@/stores/loadingStore";
import {commonStore, localStorageStore, userStore} from "@/stores";
import {authService} from "@/services";

const LoadingIndicator = defineAsyncComponent(() =>
	import("@/components/shared/Utils/LoadingIndicator.vue")
);
const AppLayout = defineAsyncComponent(() =>
	import("@/components/shared/AppLayout.vue")
);

const DialogBox = defineAsyncComponent(() =>
	import("@/components/shared/Utils/DialogBox.vue")
);

const dialog = ref<InstanceType<typeof DialogBox>>();
const toaster = ref<InstanceType<typeof MessageToaster>>()
const {offline} = useNetworkStatus();
const env = ref({});
customEvent();
useMeta({
	title: "",
	htmlAttrs: {lang: "en", amp: true}
});
onBeforeMount(async () => {
	env.value = import.meta.env;
	if (localStorageStore.hasToken() && !userStore.isLoggedIn()) {
		await init();
	}
});
onMounted(async () => {
	// Add an ugly mac/non-mac class for OS-targeting styles.
	// I'm crying inside.
	document.documentElement.classList.add(
		navigator.userAgent.includes("Mac") ? "mac" : "non-mac"
	);
});
const init = async () => {
	loadingStore.show();
	try {
		await commonStore.init();
		await nextTick();
		// eslint-disable-next-line no-empty
	} catch (err) {
	} finally {
		loadingStore.hide();
	}
};

provide(DialogBoxKey, dialog);
provide(MessageToasterKey, toaster)
</script>

<style lang="scss">
@import "./assets/scss/main.scss";
</style>
