import type {EventName} from "@/libs/events";
import {logger} from '@/libs//utils'

const id = getIdGenerator();
export const eventBus = {
    all: new Map(),

    on(name: EventName | EventName[] | Partial<{ [K in EventName]: Closure }>, callback?: Closure) {
        if (Array.isArray(name)) {
            name.forEach(k => this.on(k, callback))
            return
        }

        if (typeof name === 'object') {
            for (const k in name) {
                this.on(k as EventName, name[k as EventName])
            }
            return
        }
        // this.all.has(name) ? this.all.get(name).push(callback) : this.all.set(name, { id: callback })
        this.all.has(name) ? this.all.get(name).push(callback) : this.all.set(name, [callback])
        return {
            unsubscribe: () => {
                this.all.delete(name)
                // if(Object.keys(this.all.get(name)).length === 0) this.all.delete(name)
            }
        }
    },

    emit(name: EventName, ...args: any[]) {
        if (this.all.has(name)) {
            // Object.keys(subscriptions[eventType]).forEach(key => subscriptions[eventType][key](arg))
            // this.all.get(name).forEach((key: any) => this.all.get(name[key])(args))
            // Object.keys(this.all.get(name)).forEach(key => this.all.get(name)[key](...args))
            this.all.get(name).forEach((cb: Closure) => cb(...args))
        } else {
            logger.warn(`Event ${name} is not listened by any component`)
        }
    }
}

function getIdGenerator() {
    let lastId = 0

    return function getNextUniqueId() {
        lastId += 1
        return lastId
    }
}
