//import {useUserStore} from "@/stores/user";

import userSubscribedMiddleware from "@/routes/middleware/userSubscribedMiddleware";
import emailVerifiedMiddleware from "@/routes/middleware/emailVerifiedMiddleware";
import type {RouteLocationNormalized} from "vue-router";

export default [
    {
        path: "/browse/:type?",
        name: "browse",
        component: () => import("@/pages/authenticated/Browse.vue"),
        meta: {
            onlyAuthenticated: true,
            layout: "PrivateLayout"
        }
    },
    {
        path: "/search",
        name: "search",
        component: () => import("@/pages/authenticated/Search.vue"),
        props: (route: RouteLocationNormalized) => ({ query: route.query.q }),
        meta: {
            onlyAuthenticated: true,
            layout: "PrivateLayout"
        }
    },
    {
        path: "/account",
        name: "account",
        component: () => import("@/pages/authenticated/account/AccountPage.vue"),
        meta: {
            onlyAuthenticated: true,
            layout: "PrivateLayout"
        },
        children: [
            {
                path: "setting",
                name: "account.setting",
                component: () => import("@/pages/authenticated/account/SettingPage.vue")
            },
            {
                path: "invoice",
                name: "account.invoice",
                component: () => import("@/pages/authenticated/account/TransactionsPage.vue")
            },
            {
                path: "subscription",
                name: "account.subscription",
                component: () => import("@/pages/authenticated/account/SubscriptionPage.vue")
            },
            {
                path: "me",
                name: "account.me",
                component: () => import("@/pages/authenticated/profiles/Info.vue")
            },
            {
                path: "watch-list",
                name: "account.watchlist",
                component: () => import("@/pages/authenticated/account/WatchList.vue")
            }
        ]
    },
    {
        path: "/profiles",
        name: "profile.list",
        component: () => import("@/pages/authenticated/profiles/ProfileListPage.vue"),
        meta: {
            onlyAuthenticated: true,
            layout: "PrivateLayout"
        },
        //beforeEnter: [emailVerifiedMiddleware]
    },
    {
        path: "/profiles/:uuid",
        name: "profile.single",
        component: () => import("@/pages/authenticated/profiles/SingleProfilePage.vue"),
        meta: {
            onlyAuthenticated: true,
            layout: "PrivateLayout"
        },
        //beforeEnter: [emailVerifiedMiddleware]
    },
    {
        path: "/news",
        name: "news.list",
        component: () => import("@/pages/authenticated/news/NewListPage.vue"),
        meta: {
            onlyAuthenticated: true,
            layout: "PrivateLayout"
        },
        //beforeEnter: [emailVerifiedMiddleware]
    },
    {
        path: "/news/:uuid",
        name: "news.single",
        component: () => import("@/pages/authenticated/news/SingleNewsPage.vue"),
        meta: {
            onlyAuthenticated: true,
            layout: "PrivateLayout"
        },
        //beforeEnter: [emailVerifiedMiddleware]
    },
    {
        path: "/movie/:uuid/:customPlayer?",
        name: "movie",
        component: () => import("@/pages/authenticated/movie/MovieInfo.vue"),
        meta: {
            onlyAuthenticated: true,
            layout: "PrivateLayout"
        }
    },
    {
        path: "/movie/:uuid/stream/:episode?",
        name: "movie.stream",
        component: () => import("@/pages/authenticated/movie/Stream.vue"),
        meta: {
            onlyAuthenticated: true,
            layout: "BlankLayout"
        },
        beforeEnter: [userSubscribedMiddleware]
        //beforeEnter: [emailVerifiedMiddleware, userSubscribedMiddleware]
    },
    {
        path: "/movie/:uuid/stream/custom-controls/:episode?",
        name: "movie.stream.custom",
        component: () => import("@/pages/authenticated/movie/sample/Stream.vue"),
        meta: {
            onlyAuthenticated: true,
            layout: "BlankLayout"
        },
        beforeEnter: [userSubscribedMiddleware]
        //beforeEnter: [emailVerifiedMiddleware, userSubscribedMiddleware]
    },
    {
        path: "/billing/plans",
        name: "plans",
        component: () => import("@/pages/authenticated/billing/PlanPage.vue"),
        meta: {
            onlyAuthenticated: true,
            layout: "PrivateLayout"
        },
        //beforeEnter: [emailVerifiedMiddleware]
    },
    {
        path: "/contact-us",
        name: "contact-us",
        component: () => import("@/pages/public/content/ContactUsPage.vue"),
        meta: {
            onlyAuthenticated: true,
            layout: "PrivateLayout"
        }
    }
];
