import {reactive} from 'vue'
import {merge} from 'lodash'
import type {Settings} from "@/libs/types/settings";

export const settingStore = {
    state: reactive<Settings>({}),

    init(settings: Settings) {
        merge(this.state, settings)
    },
}
