import type {InjectionKey} from 'vue'
import {inject} from 'vue'

export const use = <T>(value: T, cb: (arg: T) => void) => {
    if (typeof value === 'undefined' || value === null) {
        return
    }

    cb(value)
}


export const arrayify = <T>(maybeArray: T | Array<T>) => ([] as Array<T>).concat(maybeArray)

// @ts-ignore
export const noop = () => {
}

export const requireInjection = <T>(key: InjectionKey<T>, defaultValue?: T) => {
    const value = inject(key, defaultValue)

    if (typeof value === 'undefined') {
        throw new Error(`Missing injection: ${key.toString()}`)
    }

    return value
}

export const limitBy = <T>(arr: T[], count: number, offset: number = 0): T[] => arr.slice(offset, offset + count)

