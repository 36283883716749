import {reactive} from 'vue'
import {cache, http} from '@/services'
import type {User} from "@/libs/types/user";
import {Endpoints} from "@/libs/EndpointConstant";
import {listStore} from "@/stores/listStore";
import {useSettings} from "@/composables/useSettings";
import {userStore} from "@/stores/userStore";

interface CommonStoreState {
    inMaintenanceMode: boolean,
    isSystemError: boolean,
    currentUser: User
    settings: []
    banners: Array<object>,
    genres: [],
    paymentMethods: [],
}

export const commonStore = {
    state: reactive<CommonStoreState>({
        inMaintenanceMode: false,
        isSystemError: false,
        currentUser: undefined as unknown as User,
        settings: [],
        banners: [],
        genres: [],
        paymentMethods: []
    }),

    init: async function () {
        Object.assign(this.state, await cache.remember(['default-data'], async () => await http.get<CommonStoreState>(Endpoints.DATA)))
        // If this is a new user, initialize his preferences to be an empty object.
        //this.state.currentUser.preferences = this.state.currentUser.preferences || {}
        this.state.banners = await cache.remember(['banners'], async () => await listStore.fetchBanners());
        userStore.init(this.state.currentUser)
        // if (authService.hasToken() && !userStore.isLoggedIn()) {
        //     await userStore.init(await userStore.getUser());
        // }
        //settingStore.init(this.state.settings)

        useSettings().setMultiple(this.state.settings)

        return this.state
    }
}
