import type {AxiosInstance, AxiosRequestConfig, Method} from 'axios'
import Axios from "axios";
import {commonStore, localStorageStore, userStore} from "@/stores";
import {loadingStore} from "@/stores/loadingStore";
import {eventBus} from "@/libs/utils";
import router from "@/routes";
import {localStorageService} from "@/services/localStorageService";

export interface AxiosRequestConfigExtended extends AxiosRequestConfig {
    showLoader: boolean
}

class Http {
    client: AxiosInstance

    constructor() {

        this.client = Axios.create({
            headers: {
                'Cache-Control': 'no-cache',
                'X-Api-Version': 'v1'
            }
        })

        this.client.defaults = {
            ...this.client.defaults, ...{
                showLoader: true
            }
        }
        // Intercept the request to make sure the token is injected into the header.
        this.client.interceptors.request.use((config: AxiosRequestConfig): AxiosRequestConfig => {
                if (config?.showLoader) {
                    loadingStore.pending();
                }
                config!.headers!['X-Requested-With'] = 'XMLHttpRequest'
                const isOFilmsApiUrl = config.url?.startsWith(import.meta.env.OFILMS_API_HOST);
                if (localStorageStore.hasToken() && isOFilmsApiUrl) {
                    config!.headers!['Authorization'] = `Bearer ${localStorageStore.getToken()}`
                }
                return config
            },
            error => {
                if (error?.config?.showLoader) {
                    loadingStore.done()
                }
                return Promise.reject(error);
            })

        // Intercept the response and…
        this.client.interceptors.response.use(response => {
            if ([200].includes(response.status)) {
                commonStore.state.inMaintenanceMode = false;
                commonStore.state.isSystemError = false;
                localStorageService.remove('maintenanceMode');
                localStorageService.remove('systemError');
            }
            if (response?.config?.showLoader) {
                loadingStore.done();
            }
            // …get the token from the header or response data if exists, and save it.
            const token = response.headers.authorization || response?.data?.data?.Authorization || response?.data?.data?.accessToken
            token && localStorageStore.setToken(token)

            return response
        }, async (error) => {
            const originalConfig = error.config;
            const {response, config} = error || {};
            const {status, data} = response || {};
            if (status === 503) {
                commonStore.state.inMaintenanceMode = true;
                localStorageService.set('maintenanceMode', true);
                commonStore.state.isSystemError = false;
                localStorageService.remove('systemError');
                await router.push({name: 'maintenance'}) // maintenancePage
            } else if ([400, 422, 500].includes(status)) {
                commonStore.state.inMaintenanceMode = false;
                localStorageService.remove('maintenanceMode');
                if (status == 500){
                    commonStore.state.isSystemError = false;
                    localStorageService.remove('systemError');
                    //commonStore.state.isSystemError = true;
                    //localStorageService.set('systemError', true);
                }
            }
            if (response?.config?.showLoader) {
                loadingStore.done();
            }

            // Also, if we receive a Bad Request / Unauthorized error
            //if (localStorageStore.hasToken()) {
            try {

                // TODO: Find more reliable way to determine when Token state
                if (status === 401 && data.message === "Token has expired") {
                    const res = await userStore.postRefreshAccessToken();
                    localStorageStore.setToken(res.accessToken);
                    return this.client.request(config);
                }

                if (status === 401 ||
                    (status === 500 && (
                        data.message === 'Token has expired and can no longer be refreshed'
                        || data.message === 'The token has been blacklisted' || data.message == 'Server Error'
                    ))
                ) {
                    eventBus.emit('LOG_OUT')
                }
            } catch (_error) {
                return Promise.reject(_error);
            }

        //     if (!(error.config.method === 'post' && error.config.url === 'account/me')) {
        //   // the token must have expired. Log out.
        //   eventBus.emit('LOG_OUT')
        // }
            //}

            //if (error.response?.status === 400 || error.response?.status === 401) {
            // and we're not trying to log in
            //if (/\/account\/me\/?$/.test(error.config.url)) {
            // the token must have expired. Log out.
            //eventBus.emit('LOG_OUT')
            //}
            //}
            // if (error.response?.status === 401 && !originalConfig._retry) {
            //     originalConfig._retry = true;
            //     try {
            //         if (authService.hasToken()) {
            //             const rs = await userStore.refreshAccessToken();
            //             const {accessToken} = rs?.data?.data?.accessToken
            //             authService.setToken(accessToken)
            //             originalConfig!.headers!.Authorization = `Bearer ${accessToken}`
            //
            //             return this.client(originalConfig);
            //         }
            //     } catch (_error) {
            //         // and we're not trying to log in
            //         if (!(error.config.method === 'post' && (/\/account\/me\/?$/.test(error.config.url) || /\/auth\/refresh\/?$/.test(error.config.url) || /\/data\/?$/.test(error.config.url)))) {
            //             // the token must have expired. Log out.
            //             eventBus.emit('LOG_OUT')
            //         }
            //     }
            // }
            return Promise.reject(error)
        })
    }

    /**
     *
     * @param method
     * @param url
     * @param config
     * @param onUploadProgress
     */
    public request<T>(method: Method, url: string, config: AxiosRequestConfig = {}, onUploadProgress?: any) {
        config = {
            data: {} as Record<string, any>,
            params: {},
            url: url,
            showLoader: false,
            method: method as Method,
            onUploadProgress: onUploadProgress as any,
            ...config
        }
        return this.client.request(config) as Promise<{ data: T }>
    }

    /***
     *
     * @param url
     * @param config
     */
    public async get<T>(url: string, config: AxiosRequestConfig = {}) {
        return (await this.request<T>('get', url, config)).data
    }

    /***
     *
     * @param url
     * @param config
     * @param onUploadProgress
     */
    public async post<T>(url: string, config: AxiosRequestConfig = {}, onUploadProgress?: any) {
        return (await this.request<T>('post', url, config, onUploadProgress)).data
    }

    /**
     *
     * @param url
     * @param config
     */
    public async put<T>(url: string, config: AxiosRequestConfig = {}) {
        return (await this.request<T>('put', url, config)).data
    }

    /**
     *
     * @param url
     * @param config
     */
    public async delete<T>(url: string, config: AxiosRequestConfig = {}) {
        return (await this.request<T>('delete', url, config)).data
    }
}

export const http = new Http()
